export const routes = [
  {
    path: '/accounts/membership',
    name: 'account_membership',
    component: () => import('../pages/AccountMembershipPage.vue'),
  },
  {
    path: '/company/',
    name: 'useradmin',
    component: () => import('../pages/CompanyAdminPage.vue'),
  },
  {
    path: '/accounts/profile',
    name: 'account_profile',
    props: true,
    component: () => import('../pages/AccountProfilePage.vue'),
  },
  {
    path: '/company/users/:id',
    name: 'member_detail',
    props: true,
    component: () => import('../pages/MemberDetailPage.vue'),
  },
  {
    path: '/company/users',
    name: 'users',
    props: true,
    component: () => import('../pages/MembersPage.vue'),
  },
  {
    path: '/company/partnership',
    name: 'partnership',
    props: true,
    component: () => import('../pages/PartnershipsPage.vue'),
  },
  {
    path: '/company/partnership/partners',
    name: 'partners',
    props: true,
    component: () => import('../pages/AvailablePartnersPage.vue'),
  },
  {
    path: '/company/partnership/:partnershipId',
    name: 'partner_info',
    props: true,
    component: () => import('../pages/PartnershipDetailPage.vue'),
  },
  {
    path: '/company/connected_systems',
    name: 'connected_systems',
    props: true,
    component: () => import('../pages/ConnectedSystemsPage.vue'),
  },
  {
    path: '/company/connected_systems/add',
    name: 'connected_systems_add',
    props: true,
    component: () => import('../pages/ConnectedSystemPage.vue'),
  },
  {
    path: '/company/connected_systems/:id',
    name: 'connected_systems_edit',
    props: true,
    component: () => import('../pages/ConnectedSystemPage.vue'),
  },
  {
    path: '/company/notifications',
    name: 'notifications',
    component: () => import('../pages/NotificationsPage.vue'),
  },
  {
    path: '/company/notifications/preferences',
    name: 'notification-preferences',
    component: () => import('../pages/NotificationPreferencesPage.vue'),
  },
  {
    path: '/company/exception-management/approval-groups',
    name: 'approval-groups',
    component: () => import('../pages/ApprovalGroupsPage.vue'),
  },
  {
    path: '/company/exception-management/approval-groups/:id',
    name: 'approval-group',
    component: () => import('../pages/ApprovalGroupPage.vue'),
  },
  {
    path: '/company/exception-management/requestor-groups',
    name: 'requestor-groups',
    component: () => import('../pages/RequestorGroupsPage.vue'),
  },
  {
    path: '/company/exception-management/requestor-groups/:id',
    name: 'requestor-group',
    component: () => import('../pages/RequestorGroupPage.vue'),
  },
  {
    path: '/company/noexchangerole/',
    name: 'no-exchangerole',
    meta: { msg: 'noExchangeRole' },
    component: () => import('../pages/NoPermissionPage.vue'),
  },
  {
    path: '/company/nopartnership/',
    name: 'no-partnership',
    meta: {
      msg: 'noPartnership',
      linkTo: { name: 'partnership' },
      linkText: 'Partnership Page',
    },
    component: () => import('../pages/NoPermissionPage.vue'),
  },
  {
    path: '/company/nopermission/',
    name: 'no-permission',
    meta: { msg: 'noPermission' },
    component: () => import('../pages/NoPermissionPage.vue'),
  },
  {
    path: '/company/pricing-engine/',
    name: 'pricing-engine-settings',
    component: () => import('../pages/PricingEngineSettingsPage.vue'),
  },
];
